<template>
  <v-card
    class="custom-form scrollable-dialog"
    color="#95a2b710"
  >
    <v-card-text class="dialog__inputs">
      <slot name="form" />
    </v-card-text>

    <v-card-actions v-if="!hideActions">
      <v-row>
        <v-col>
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              v-shortkey="{ esc:['esc'] }"
              @shortkey.native="closeDialog()"
              @click="closeDialog()"
            >
              Anuluj
            </v-btn>
            <slot name="submit" />
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    hideActions: {
      type: Boolean,
      dafault: false
    }
  },
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog
    }),
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog'
    })
  }
}
</script>

<style lang="scss" scoped>
.scrollable-dialog {
  overflow: auto;
  max-height: calc(90vh - 56px);
  //56px is the height of title bar
}
</style>
